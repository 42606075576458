/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "src/assets/styles/variables.scss";

@font-face {
    font-family: 'Myriad Pro Regular';
    src: local('Myriad Pro Regular'), url('../fonts/MYRIADPRO-REGULAR.woff') format('woff');
}

html,
body {
    min-height: 100%;
    margin: 0;
}

body {
    font-family: Myriad Pro Regular;
    font-style: normal;
    overflow-wrap: break-word;
}

.title {
    color: black;

    font-weight: 700;
    font-size: 36px;
}

.subtitle {
    color: black;

    font-weight: 700;
    font-size: 16px;
}

.text {
    color: black;

    font-weight: 400;
    font-size: 14px;
}

.mat-select {
    font-family: Myriad Pro Regular;
}

.mat-select .mat-select-arrow {
    border-left: 10px solid transparent !important;
    border-right: none !important;
    border-top: 100% solid transparent !important;
    border-image-source: url('../icons/arrow-down.png') !important;
    border-image-repeat: stretch !important;
}

.mat-button {
    box-sizing: border-box !important;

    gap: 10px !important;

    background: $main-color  !important;
    color: white !important;

    border: 1px solid $main-color  !important;
    border-radius: 30px !important;

    font-weight: 700 !important;
    font-size: 16px !important;
    text-align: center !important;
    text-transform: uppercase !important;

    font-family: Myriad Pro Regular !important;

    height: 40px !important;

    padding-top: 2px !important;
}

.mat-button-success {
    background: $success-color  !important;
    color: white !important;

    border: 1px solid $success-color  !important;
}

.mat-button-error {
    background: $error-color  !important;
    color: white !important;

    border: 1px solid $error-color  !important;
}

.mat-button-inversed {
    background: white !important;
    color: $main-color  !important;
}

.mat-checkbox {
    font-family: Myriad Pro Regular;
}

.mat-option-text {
    font-family: Myriad Pro Regular;
}

.mat-error {
    font-family: Myriad Pro Regular;
}

::-webkit-scrollbar {
    width: 4px;
    overflow-y: scroll;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}